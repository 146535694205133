<template>
  <div>
    <div style="position: absolute; top: 20px; right: 20px">
      <el-button type="primary" size="small" @click="allAgree">全部同意</el-button>
      <el-button type="danger" size="small" @click="allRefuse">全部拒绝</el-button>
    </div>
    <com-table ref="table" :columns="[
      {prop:'headimg',label:'头像',width:'80' , align:'center',slot:'avatar'},
      {prop:'nickname',label:'申请人',width:'140', align:'center'},
      {prop:'phone',label:'手机号',width:'115', align:'center',hidden:userType !== 2},
      {prop:'apply_typeStr',label:'申请类型',width:'200', align:'center'},
      {prop:'apply_group_name',label:'加入团队', align:'center',hidden:userType === 2},
      {prop:'apply_create_date',label:'申请时间', align:'center'},
      {prop:'apply_amount',label:'申请优惠金额',align:'center',hidden:userType !== 2},
      {prop:'apply_statusStr',label:'申请状态', align:'center'},
      {label:'操作',width:'140',slot:'action',fixed:'right', align:'center',},
    ]"
               :auto-load="false"
               :data="{
                  url:'/xapi/user/applyList',
               }"
               :page-options="false"
               :total.sync="total"
               :page-size="pageSize"
               @row-click="(row)=>{$refs[row.apply_type+'Dialog'] && $refs[row.apply_type+'Dialog'].visibled((currentData = {...(row.apply_params || {}),...row,edit_disabled:true,price:row.apply_amount,status:row.apply_status,dialog_title:row.apply_status === 0 ? '详情' : null,reason:row.apply_check_reason}) && true)}"
    >
      <div slot="action" slot-scope="{row}">
        <template v-if="row.is_apply">
          <el-button-group>
            <el-button type="danger" size="mini"
                       @click.stop="$refs[row.apply_type+'Dialog'] && $refs[row.apply_type+'Dialog'].visibled((currentData = {...(row.apply_params || {}),...row,status:2,price:row.apply_amount}) && true)"
                       :disabled="row.apply_status!==0"
            >拒绝</el-button>
            <el-button type="success" size="mini"
                       @click.stop="$refs[row.apply_type+'Dialog'] && $refs[row.apply_type+'Dialog'].visibled((currentData = {...(row.apply_params || {}),...row,status:1,price:row.apply_amount}) && true)"
                       :disabled="row.apply_status!==0"
            >同意</el-button>
          </el-button-group>
        </template>
        <template v-else>
          <el-button type="primary" size="mini"
                     @click.stop="$refs[row.apply_type+'Dialog'] && $refs[row.apply_type+'Dialog'].visibled((currentData = {...(row.apply_params || {}),...row,edit_disabled:true,price:row.apply_amount,status:row.apply_status,dialog_title:row.apply_status === 0 ? '详情' : null,reason:row.apply_check_reason}) && true)"
          >查看</el-button>
        </template>
      </div>
    </com-table>
    <diy-dialog ref="teamDialog" :title="currentData.dialog_title || (currentData.status === 1 ? '同意':'拒绝')" center>
      <form-create v-model="currentData" :components="[
        {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
        {name:'form-item',props:{prop:'apply_group_name',disabled:true,label:'团队名称'}},
        {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
        {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
        {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
      ]" :label-width="'100px'" :disabled="currentData.edit_disabled === true">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.teamDialog.visibled(false)">关 闭</el-button>
        <el-button type="primary" v-if="!currentData.edit_disabled" @click="handleCommand('teamApply',currentData,currentData.status,()=>{$refs.teamDialog.visibled(false)})">确 定</el-button>
      </div>
    </diy-dialog>
    <diy-dialog ref="TeachPlanBDialog" :title="currentData.dialog_title || (currentData.status === 1 ? '通过':'拒绝')" center>
      <form-create v-model="currentData" :components="[
        {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
        {name:'form-item',props:{prop:'apply_group_name',disabled:true,label:'教案名称'}},
        {name:'form-item',props:{prop:'buy_type',disabled:true,label:'教案类型',type:'select',data:[{name:'普通许可使用',value:1},{name:'高级许可使用',value:2}]}},
        {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
        {name:'form-item',props:{prop:'apply_reason',label:'申请理由',disabled:true,type:'textarea'}},
        {name:'form-item',props:{prop:'old_price',disabled:true,label:'价格',type:'number'},slots:{after:'元'}},
        {name:'form-item',props:{prop:'price',label:'成交金额',type:'number',max:currentData.old_price||null},slots:{after:'元'}},
        {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
      ]" :label-width="'100px'" :disabled="currentData.edit_disabled === true">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.TeachPlanBDialog.visibled(false)">关 闭</el-button>
        <el-button type="primary" v-if="!currentData.edit_disabled" @click="handleCommand('teachPlanApply',currentData,currentData.status,()=>{$refs.TeachPlanBDialog.visibled(false)})">确 定</el-button>
      </div>
    </diy-dialog>
    <diy-dialog ref="CourseWhiteDialog" :title="currentData.dialog_title || (currentData.status === 1 ? '通过':'拒绝')" center>
      <form-create v-model="currentData" :components="[
        {name:'form-item',props:{prop:'apply_group_name',disabled:true,label:'课程名称'}},
        {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
        {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
        {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
      ]" :label-width="'100px'" :disabled="currentData.edit_disabled === true">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.CourseWhiteDialog.visibled(false)">关 闭</el-button>
        <el-button type="primary" v-if="!currentData.edit_disabled" @click="handleCommand('CourseWhite',currentData,currentData.status,()=>{$refs.CourseWhiteDialog.visibled(false)})"
        >确 定</el-button>
      </div>
    </diy-dialog>
    <!-- 基准白名单 -->
    <diy-dialog ref="CourseBaseUserDialog" :title="currentData.dialog_title || (currentData.status === 1 ? '通过':'拒绝')" center>
      <form-create v-model="currentData" :components="[
        {name:'form-item',props:{prop:'apply_group_name',disabled:true,label:'课程名称'}},
        {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
        {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
        {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
      ]" :label-width="'100px'" :disabled="currentData.edit_disabled === true">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.CourseBaseUserDialog.visibled(false)">关 闭</el-button>
        <el-button type="primary" v-if="!currentData.edit_disabled" @click="handleCommand('CourseBaseUser',currentData,currentData.status,()=>{$refs.CourseBaseUserDialog.visibled(false)})"
        >确 定</el-button>
      </div>
    </diy-dialog>

    <!-- 学生申请报名审核 -->
    <diy-dialog ref="StudentBuyCourseDialog" :title="currentData.dialog_title || (currentData.status === 1 ? '通过':'拒绝')" center>
      <form-create v-model="currentData" :components="[
        {name:'form-item',props:{prop:'apply_group_name',disabled:true,label:'课程名称'}},
        {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
        {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
        {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
      ]" :label-width="'100px'" :disabled="currentData.edit_disabled === true">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.StudentBuyCourseDialog.visibled(false)">关 闭</el-button>
        <el-button type="primary" v-if="!currentData.edit_disabled" @click="handleCommand('StudentBuyCourse',currentData,currentData.status,()=>{$refs.StudentBuyCourseDialog.visibled(false)})"
        >确 定</el-button>
      </div>
    </diy-dialog>

    <!-- 团队关注审核审核 -->
    <diy-dialog ref="TeamFocusDialog" :title="currentData.dialog_title || (currentData.status === 1 ? '通过':'拒绝')" center>
      <form-create v-model="currentData" :components="[
        {name:'form-item',props:{prop:'apply_group_name',disabled:true,label:'团队名称'}},
        {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
        {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
        {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
        {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
      ]" :label-width="'100px'" :disabled="currentData.edit_disabled === true">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.TeamFocusDialog.visibled(false)">关 闭</el-button>
        <el-button type="primary" v-if="!currentData.edit_disabled" @click="handleCommand('TeamFocusApply',currentData,currentData.status,()=>{$refs.TeamFocusDialog.visibled(false)})"
        >确 定</el-button>
      </div>
    </diy-dialog>

    <diy-dialog ref="StudentTxDialog" :title="currentData.dialog_title || (currentData.status===1 ? '通过' : '驳回')" center>
      <!-- <form-create v-model="currentData" :disabled="currentData.edit_disabled===true" :components="[
          {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
          {name:'form-item',props:{prop:'leave_type',disabled:true,label:'退学方式',type:'select',data:{ url:'/xapi/course.course/studentOptions/type/leave_type' }}},
          {name:'form-item',props:{prop:'contact_mobile',disabled:true,label:'联系方式'}},
          {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
          {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
          {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
          {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
          {name:'form-item',props:{prop:'refund_price',label:'退款金额',required:true,type:'number',step:0.01,max:currentData.price},condition:'{{leave_type}}==2||{{leave_type}}==3',slots:{append:'元'}},
        ]" :label-width="'100px'">
      </form-create> -->
      <form-create v-model="currentData" :disabled="currentData.edit_disabled===true" :components="[
          {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
          {name:'form-item',props:{prop:'leave_type',disabled:true,label:'退学方式',type:'select',data:{ url:'/xapi/course.course/studentOptions/type/leave_type' }}},
          {name:'form-item',props:{prop:'contact_mobile',disabled:true,label:'联系方式'}},
          {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'申请理由'}},
          {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
          {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
          {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
          {name:'form-item',props:{prop:'refund_price',label:'退款金额',required:true,type:'number',step:0.01,max:currentData.price},slots:{append:'元'}},
        ]" :label-width="'100px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.StudentTxDialog.visibled(false)">关 闭</el-button>
      </div>
    </diy-dialog>

    <diy-dialog ref="StudentZkDialog" :title="currentData.dialog_title || (currentData.status===1 ? '通过' : '驳回')" center>
      <form-create v-model="currentData" :disabled="currentData.edit_disabled===true" :components="[
          {name:'form-item',props:{prop:'nickname',disabled:true,label:'申请人'}},
          {name:'form-item',props:{prop:'course_name',disabled:true,label:'从......课程'}},
          {name:'form-item',props:{prop:'transfer_course_name',disabled:true,label:'到......课程'}},
          {name:'form-item',props:{prop:'apply_reason',disabled:true,label:'转课理由'}},
          {name:'form-item',props:{prop:'apply_create_date_s',disabled:true,label:'申请时间'}},
          {name:'form-item',props:{prop:'apply_check_date_s',disabled:true,label:'审核时间'},hidden:currentData.edit_disabled !== true || currentData.status === 0},
        ].concat(currentData.agree?[]:[
          {name:'form-item',props:{prop:'reason',label:'驳回理由',type:'textarea'},hidden:currentData.status !== 2},
        ])" :label-width="'100px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.StudentZkDialog.visibled(false)">关 闭</el-button>
      </div>
    </diy-dialog>
    <div :style="$attrs.pageStyle">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="$refs.table.handlePageChange($event)" :page-size="pageSize" :current-page="page" hide-on-single-page>
      </el-pagination>
    </div>
    <el-dialog title="全部拒绝" width="26%" :visible.sync="dialogTableVisible">
        <div style="display: flex;align-items: center;">
          <div style="width: 90px;">拒绝原因：</div>
          <div style="border: 1px solid #DCDFE6;border-radius: 4px;flex: 1;">
            <el-input style="width: 100%;" v-model="refuseReson" placeholder="请输入拒绝原因"></el-input>
          </div>
        </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogTableVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="confirmRefuse">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {approval_join_apply, join_list} from "@/api/me";
import DiyDialog from "@/components/com/diyDialog";

export default {
  components: {DiyDialog},
  props:{
    type:[Number,String],
  },
  data(){
    return {
      page: 1,
      pageSize:10,
      tableData:[],
      currentData:{},
      total:0,
      loading:false,
      dialogTableVisible:false,
      refuseReson:"",//拒绝原因
    };
  },
  created(){
    console.log(this.type,"typeeeeee")

  },
  methods:{
    // 一键同意
    allAgree(){
      console.log('一键同意')
      this.$confirm("是否同意全部审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          this.$http.post("/xapi/user/applyList?page=1&pageSize=999").then((res) => {
            console.log(res,"申请列表")
            let filterData=res.data.data.filter(item =>item.is_apply&&item.apply_status==0)
            filterData.forEach(v=>{
              v.id=v.apply_log_id
            })
            console.log(filterData,"filterDatafilterData")
            // return
            let data = {
            status:1,
            items:filterData
            // ids: this.tableData.map((v) => v.id).join(","),
          };
          this.$http.post("/xapi/course.course/userMoreVerify", data).then((res) => {
            this.$message({
              type: "success",
              message: "全部审核成功!",
            });
            this.$http.get("/api/v1_0_0.user/msg_not_read").then((res) => {
              this.$store.commit("SET", {
                key: "msg_notread",
                value: res.data.msg_notread,
              });
            });
            this.$refs.table.reset()
            // this.getMessageList();
            this.$emit('updateData')
          });
          });

          
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    // 一键拒绝
    allRefuse(){
      console.log('一键拒绝')
      this.dialogTableVisible=true
    },
    // 确定全部拒绝
    confirmRefuse(){

      this.$http.post("/xapi/user/applyList?page=1&pageSize=999").then((res) => {
            console.log(res,"申请列表")
            let filterData=res.data.data.filter(item =>item.is_apply&&item.apply_status==0)
            filterData.forEach(v=>{
              v.id=v.apply_log_id
            })
            console.log(filterData,"filterDatafilterData")
            // return
            let data = {
            status:2,
            reason:this.refuseReson,
            items:filterData
            // ids: this.tableData.map((v) => v.id).join(","),
          };
          console.log(data,"全部拒绝参数")
          // return
          this.$http.post("/xapi/course.course/userMoreVerify", data).then((res) => {
            this.$message({
              type: "success",
              message: "全部拒绝成功!",
            });
          this.dialogTableVisible=false

            this.$http.get("/api/v1_0_0.user/msg_not_read").then((res) => {
              this.$store.commit("SET", {
                key: "msg_notread",
                value: res.data.msg_notread,
              });
            });
            this.$refs.table.reset()
            // this.getMessageList();
            this.$emit('updateData')
          });
          });
    },
    rowClick(){
      console.log(" ---- rowClick" , ...arguments);
    },
    routeUpdate(){
      this.page = 1;
      this.tableData = [];
      this.$nextTick(()=>{this.$refs.table.reset();})
    },
    handleCommandTeamApply(item , status = 1,cb) {
      approval_join_apply({ log_id: item.apply_log_id, status: status,reply:item.reason || '' })
          .then((result) => {
            this.$message.success(status === 1 ?"已同意" :"已拒绝")
            this.routeUpdate()
            this.$emit('updateData')
            cb && cb();
          })
          .catch((err) => { })
    },
    handleCommandTeachPlanApply(item , status = 1,cb) {
      this.$http.post('/xapi/user/applyTeachPlan',{id: item.apply_log_id, status: status,reason:item.reason || '',price:item.price})
          .then((result) => {
            this.$message.success(status === 1 ?"已同意" :"已拒绝")
            this.routeUpdate()
            this.$emit('updateData')

            cb && cb();
          })
          .catch((err) => { })
    },
    handleCommandCourseWhite(item , status = 1,cb) {
      this.$http.post('/xapi/course.course/courseWhiteVerify',{...item,id: item.apply_log_id, status: status,reason:item.reason || (status === 1 ?"同意" :"")})
          .then((result) => {
            this.$message.success(status === 1 ?"已同意" :"已拒绝")
            this.routeUpdate()
            this.$emit('updateData')

            cb && cb();
          })
          .catch((err) => { })
    },
    // 基准名单
    handleCommandCourseBaseUser(item , status = 1,cb) {
      this.$http.post('/xapi/course.course/courseBaseUserVerify',{...item,id: item.apply_log_id, status: status,reason:item.reason || (status === 1 ?"同意" :"")})
          .then((result) => {
            this.$message.success(status === 1 ?"已同意" :"已拒绝")
            this.routeUpdate()
            this.$emit('updateData')

            cb && cb();
          })
          .catch((err) => { })
    },
    // 学生申请报名审核
    handleCommandStudentBuyCourse(item , status = 1,cb) {
      this.$http.post('/xapi/course.course/studentBuyCourseVerify',{...item,id: item.apply_log_id, status: status,reason:item.reason || (status === 1 ?"同意" :"")})
          .then((result) => {
            this.$message.success(status === 1 ?"已同意" :"已拒绝")
            this.routeUpdate()
            this.$emit('updateData')

            cb && cb();
          })
          .catch((err) => { })
    },
    // 团队关注审核
    handleCommandTeamFocusApply(item , status = 1,cb) {
      this.$http.post('/xapi/user.group/teamFocusVerify',{...item,id: item.apply_log_id, status: status,reason:item.reason || (status === 1 ?"同意" :"")})
          .then((result) => {
            this.$message.success(status === 1 ?"已同意" :"已拒绝")
            this.routeUpdate()
            this.$emit('updateData')

            cb && cb();
          })
          .catch((err) => { })
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep{
  .el-table__row{
    cursor: pointer !important;;
  }
  input{
    border: none !important;
    color: #000000;
  }
  .el-input.is-disabled .el-input__inner{
    border: none !important;
    color: #000000 !important;
    background: #FFFFFF;
  }
}

</style>